<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Login Attempts</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th style="width:25%">Email</th>
              <th style="width:25%">Date/Time</th>
              <th style="width:25%">Password Successful</th>
              <th style="width:25%">Two Factor Successful</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attempt, index) in loginAttempts" :key="index">
              <td>{{ attempt.email }}</td>
              <td>{{ attempt.created | prettyDateTime }}</td>
              <td>
                <span v-if="attempt.password_successful">
                  Yes
                </span>
                <span v-else>
                  No
                </span>
              </td>
              <td>
                <span v-if="attempt.two_factor_successful">
                  Yes
                </span>
                <span v-else>
                  No
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="page < length">
      <v-col class="text-right pb-10">
        <v-btn @click="page += 1" large>Load More</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'LoginAttempts',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      loginAttempts: [],
      page: 0,
      length: 0,
    };
  },
  watch: {
    page() {
      this.getAttempts();
    },
  },
  methods: {
    getAttempts() {
      axios.get(`/loginAttempts/getAll/${this.page}.json?token=${this.token}`)
          .then((response) => {
            this.loginAttempts = response.data.loginAttempts;
            this.length = response.data.length;
          });
    },
  },
  mounted() {
    this.getAttempts();
  },
};
</script>
